import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./about.css";
import "../../App.css";
import Profile from "../../assets/img/profile/profile.svg";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";

const About = ({ handleNavClick }) => {
  const scrollToContact = () => {
    handleNavClick("contact");
  };
  return (
    <div id="about">
      <div className="about">
        <h1 className="pt-3 text-center font-details pb-4 about_us divider or or--x">
          ABOUT US
        </h1>
        <Container>
          <Row className=" pb-5 align-items-center">
            <Col xs={12} md={6}>
              <Row className="justify-content-center mb-2 mr-2 ">
                <Image
                  className="profile justify-content-end"
                  alt="profile"
                  src={Profile}
                />
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className=" align-items-start p-3 my-details rounded tex align-text-left ">
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "36px",
                    lineHeight: "1.1em",
                    marginBottom: "15px",
                    fontFamily: "sans-serif",
                    color: "var(--primary-color)",
                  }}
                >
                  The World’s IT Solution's <br /> Company
                </h2>
                <p>
                  Our state-of-the-art solutions make us your trusted technology
                  partner. With a clientele across the globe, we tend to build
                  futuristic Software products that help them overhaul their
                  conventional & complex challenges.
                </p>
                <p>
                  We have been accredited with many prestigious recognitions for
                  our quality services in the IT industry. Our wide range of
                  services includes mobile and web app development using the
                  latest technologies and ensuring a smart, collaborative and
                  systematic management of all human resources working towards
                  the common and ultimate goal of our organization.
                </p>

                <button className="cusbtn rounded" onClick={scrollToContact}>
                  Get Started
                </button>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* client crousal  */}

        {/* <h1>Our Clients</h1>
        <ReactCaroussel
          autoplay={true}
          slidesToShow={3}
          slidesToScroll={3}
          infinite={true}
        >
          {Array(9)
            .fill(0)
            .map((_, index) => (
              <Card key={index} index={index} />
            ))}
        </ReactCaroussel> */}

        {/* mission vision  */}
        <Container className="mb-5">
          <Row>
            <Col xs={12} md={6}>
              <CardDeck>
                <Card className="focus mb-2">
                  <Card.Body>
                    <Card.Title className="text-center  card-title mission_vision">
                      Our Mission
                    </Card.Title>
                    <hr />
                    <Card.Text className="card-text d-flex justify-content-start flex-column">
                    
                        Our mission is to deliver client-centric solutions that
                        bring maximum productivity with minimum resources while
                        upholding service delivery principles. We aspire to
                        bring in result-oriented strategies to carve product
                        design and creation procedures.
                     
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
            <Col xs={12} md={6}>
              <CardDeck>
                <Card className="focus mb-2">
                  <Card.Body>
                    <Card.Title className="text-center  card-title mission_vision">
                      Our Vision
                    </Card.Title>
                    <hr />
                    <Card.Text className="card-text d-flex justify-content-start flex-column">
                   
                        Our vision is to create & develop a long-lasting
                        partnership that will continuously key in on focused
                        improvement initiatives, and nurture a lasting culture
                        of winning solutions and rewarding opportunities through
                        technology.
                     
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Container>
        {/* mission vision  */}
      </div>
    </div>
  );
};

export default About;
