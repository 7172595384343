import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import backend from "../../assets/img/services/backend.jpg";
import digitalmarketing from "../../assets/img/services/digitalmarketing.jpg";
import gaming from "../../assets/img/services/gaming.jpg";
import iot from "../../assets/img/services/iot.jpg";
import Image from "react-bootstrap/Image";
// import { AiOutlineClose } from "react-icons/ai";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhp } from "@fortawesome/free-brands-svg-icons";
import CardDeck from "react-bootstrap/CardDeck";
import Tilt from "react-parallax-tilt";
import "./BlogSection.css";

const BlogSection = () => {
  return (
    <div id="blogs">
      {/* cards section  */}
      <Container>
        <h1 className="text-center font-details-b pb-4">SERVICES</h1>
        <CardDeck>
          <Row className="p-0 m-0">
            <Col xs={12} sm={6} md={3}>
              <Card className="services_hover" style={{ position: "relative" }}>
                <Card.Body>
                  <div className="display_service_card">
                    <Card.Img
                      className="image-resize"
                      variant="top"
                      src={backend}
                      alt="Blog 1"
                    />
                    <a
                      className="text-dark text-decoration-none"
                      href="https://medium.com/javascript-in-plain-english/what-are-the-differences-between-state-and-props-in-react-74f21e39b172"
                      target="_blank"
                    >
                      <Card.Title>
                        Backend <br /> Development
                      </Card.Title>
                      {/* <Card.Text>
                      How you use props and state, and what each of them is?
                    </Card.Text> */}
                    </a>
                  </div>
                  <div className="hover_display_service_card services_display">
                    <Card.Body>
                      <Card.Text>
                        PHP (Hypertext Processor) is a general-purpose scripting
                        language and interpreter that is freely available and
                        widely used for web development.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Card className="services_hover" style={{ position: "relative" }}>
                <Card.Body>
                  <div className="display_service_card">
                    <Card.Img
                      className="image-resize"
                      variant="top"
                      src={digitalmarketing}
                      alt="Blog 1"
                    />
                    <a
                      className="text-dark text-decoration-none"
                      href="https://medium.com/javascript-in-plain-english/what-are-the-differences-between-state-and-props-in-react-74f21e39b172"
                      target="_blank"
                    >
                      <Card.Title>
                        Digital <br /> Marketing
                      </Card.Title>
                    </a>
                  </div>
                  <div className="hover_display_service_card services_display">
                    <Card.Body>
                      <Card.Text>
                        Digital marketing, also called online marketing, is the
                        promotion of brands to connect with potential customers
                        using the internet and other forms of digital
                        communication.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Card className="services_hover" style={{ position: "relative" }}>
                <Card.Body>
                  <div className="display_service_card">
                    <Card.Img
                      className="image-resize"
                      variant="top"
                      src={iot}
                      alt="Blog 1"
                    />
                    <a
                      className="text-dark text-decoration-none"
                      href="https://medium.com/javascript-in-plain-english/what-are-the-differences-between-state-and-props-in-react-74f21e39b172"
                      target="_blank"
                    >
                      <Card.Title>
                        IOT <br /> Development
                      </Card.Title>
                      {/* <Card.Text>
                      How you use props and state, and what each of them is?
                    </Card.Text> */}
                    </a>
                  </div>
                  <div className="hover_display_service_card services_display">
                    <Card.Body>
                      <Card.Text>
                        The term IoT, or Internet of Things, refers to the
                        collective network of connected devices and the
                        technology that facilitates communication between
                        devices and the cloud, as well as between the devices
                        themselves.
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Card className="services_hover" style={{ position: "relative" }}>
                <Card.Body>
                  <div className="display_service_card">
                    <Card.Img
                      className="image-resize"
                      variant="top"
                      src={gaming}
                      alt="Blog 1"
                    />
                    <a
                      className="text-dark text-decoration-none"
                      href="https://medium.com/javascript-in-plain-english/what-are-the-differences-between-state-and-props-in-react-74f21e39b172"
                      target="_blank"
                    >
                      <Card.Title>
                        Game <br /> Development
                      </Card.Title>
                      {/* <Card.Text>
                      How you use props and state, and what each of them is?
                    </Card.Text> */}
                    </a>
                  </div>
                  <div className="hover_display_service_card services_display">
                    <Card.Body>
                      <Card.Text>
                        Game Development is the art of creating games and
                        describes the design, development and release of a game.
                        It may involve concept generation, design, build, test
                        and release. 
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </CardDeck>
      </Container>
    </div>
  );
};
export default BlogSection;
