import React from "react";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import L_HTML5 from "../../assets/img/skills/html-5.svg";
import L_CSS3 from "../../assets/img/skills/css3.svg";
import L_SASS from "../../assets/img/skills/sass-1.svg";
import L_BOOTSTRAP from "../../assets/img/skills/bootstrap-4.svg";
import L_REACT from "../../assets/img/skills/react.svg";
import L_REDUX from "../../assets/img/skills/redux.svg";
import L_MATERIALUI from "../../assets/img/skills/material-ui-1.svg";
import L_REACT_BOOTSTRAP from "../../assets/img/skills/react-bootstrap.svg";
import L_GIT from "../../assets/img/skills/git-icon.svg";
import L_HEROKU from "../../assets/img/skills/heroku.svg";
import L_GITHUB_PAGES from "../../assets/img/skills/github.svg";
import L_JAVASCRIPT from "../../assets/img/skills/javascript.svg";
import L_NETLIFY from "../../assets/img/skills/netlify-icon.svg"
import L_FIREBASE from "../../assets/img/skills/firebase-icon.svg"
import L_VERCEL from "../../assets/img/skills/logo-vercel.svg"
import L_TAILWIND from "../../assets/img/skills/tailwindcss-icon.svg"


// skills
import { skills } from "./db-skills";

import "./skills.css";

const Skills = () => {
    return (
        <div className="pt-5 pb-3" id="skills">
            <h1 className="text-center font-details-b pt-4 pb-4">TECHNOLOGY</h1>
            <CardDeck>
                <Row className="p-0 m-0">
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_REACT}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>React</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_HTML5}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_CSS3}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_REDUX}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_JAVASCRIPT}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_BOOTSTRAP}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </CardDeck>
            <CardDeck>
                <Row className="p-0 m-0">
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_VERCEL}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>React</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_FIREBASE}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_MATERIALUI}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_GITHUB_PAGES}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_GIT}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={2}>
                        <Card className="skillcard">
                            <Card.Body>
                                <Card.Img
                                    className=""
                                    variant="top"
                                    src={L_TAILWIND}
                                    alt="Blog 1"
                                />
                                <Card.Text>
                                    <h1>hello</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </CardDeck>
        </div>
    );
};

export default Skills;