import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faEnvelope,
  faPhone,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import company_logo from "../../assets/icons/logo.png";
import "./contact.css";
import "../../App.css";
import Form from "react-bootstrap/Form";

const Contact = () => {
  return (
    <div id="contact">
      <div className="contactbox">
        <Container>
          <h1 className="pt-3 text-center font-details pb-4 about_us divider or or--x">
            Contact Us
          </h1>
          <Row className="pb-5">
            <Col xs={12} sm={12} md={8} className="contactform text-white">
              <h2
                style={{
                  fontSize: "1.3em",
                  fontWeight: "700",
                  marginBottom: "20px",
                }}
              >
                Send Us Message
              </h2>
              <Form>
                <Row style={{ marginLeft: "0", marginRight: "0" }}>
                  <Col xs="12" md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="email" placeholder="Name" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control type="email" placeholder="Phone number" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Subject</Form.Label>
                      <Form.Control type="email" placeholder="Subject" />
                    </Form.Group>
                  </Col>
                  <Col xs="12" md={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                  </Col>
                  <Button className="cusbtn rounded" type="submit">
                    Submit
                  </Button>
                </Row>
              </Form>
            </Col>
            <Col xs={12} sm={12} md={4} className="Contactdeatils">
              <div className="mapsec">
                <iframe
                  title="Google Map"
                  width="auto"
                  height="auto"
                  style={{ border: 0 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.2647257159138!2d77.34920077617149!3d28.651791183204587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfac7283290c9%3A0x7269ffc0bed77a91!2sGardenia%20Gitanjali!5e0!3m2!1sen!2sin!4v1701165309011!5m2!1sen!2sin"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <h1 className="pt-3 text-center font-details-b pb-3">CONTACT Us</h1> */}

      {/* <Jumbotron className="contact-jumbotron">
        <Row>
          <Col className="d-flex justify-content-center flex-wrap">
            <div className="m-2">
              <a href="mailto:adiba.abid_@hotmail.com" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-danger" title="adiba.abid_@hotmail.com">
                  <i className="fas fa-envelope-square"></i> Email Me
                </Button>
              </a>
            </div>
            <div className="m-2">
            <a href="https://www.linkedin.com/in/adiba-abid-2658b9199/" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-primary" title="Visit my LinkenIn">
                  <i className="fab fa-linkedin"></i> LinkedIn
                </Button>
              </a>
            </div>
            
            <div className="m-2">
            <a href="https://github.com/AdibaAbid" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-dark" title="My other projects">
                  <i className="fab fa-github-square"></i> GitHub
                </Button>
              </a>
            </div>
            <div className="m-2">
              <a href="https://medium.com/@adibaabid" target="_blank" rel="noopener noreferrer">
                <Button id="medium-btn" variant="outline-info" title="Lets write together!">
                <i class="fab fa-youtube"></i> Medium
                </Button>
              </a>
            </div>
            <div className="m-2">
              <a href="https://twitter.com/home" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-info" title="Tweets are welcomed!">
                  <i className="fab fa-twitter"></i> Twitter
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Jumbotron> */}
    </div>
  );
};

export default Contact;
