import React from "react";
import "./footer.css";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faEnvelope,
  faPhone,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import company_logo from "../../assets/icons/logo.png";

const Footer = () => {
  return (
    <div className="footer_background pt-5">
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <Image src={company_logo} style={{ width: "100px" }} rounded />
            <div>
              We work with a passion of taking challenges and creating new ones
              in advertising sector.
            </div>
          </Col>
          <Col xs={12} md={3}>
            <h1 className="display-6 footer_heading">SERVICES</h1>
            <ListGroup as="ul">
              <ListGroup.Item className="contact_list" as="li">
                IOT
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                PHP
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                React
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                C#
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs={12} md={3}>
            <h1 className="display-6 footer_heading">SITE MAP</h1>
            <ListGroup as="ul">
              <ListGroup.Item className="contact_list" as="li">
                Home
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                About
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                Technology
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                Projects
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                Services
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs={12} md={3}>
            <h1 className="display-6 footer_heading">CONTACT</h1>
            <ListGroup as="ul">
              <ListGroup.Item className="contact_list" as="li">
                <FontAwesomeIcon
                  className="footer_icon"
                  icon={faHouse}
                  size="2x"
                />
                1601, Tower A Gardenia Gitanjali Vasundhara Sector 18
                Ghaziabad 201012
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                <FontAwesomeIcon
                  className="footer_icon"
                  icon={faEnvelope}
                  size="2x"
                />
                info@1-tcp.com
              </ListGroup.Item>
              <ListGroup.Item className="contact_list" as="li">
                <FontAwesomeIcon
                  className="footer_icon"
                  icon={faPhone}
                  size="2x"
                />
                7042153444
              </ListGroup.Item>
              {/* <ListGroup.Item className="contact_list" as="li">
                <FontAwesomeIcon
                  className="footer_icon"
                  icon={faFax}
                  size="2x"
                />
                +012467354
              </ListGroup.Item> */}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <div className="py-2 mt-5 text-center footer-style">
      Copyright © 2023 One TCP Solutions. All Rights Reserved.
        {/* One TCP Solutions ©️&nbsp; 2023 */}
      </div>
    </div>
  );
};

export default Footer;
